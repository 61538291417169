import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Pagination",
  "slug": "pagination"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Filtering, Pagination and Sorting`}</h1>
    <p>{`enableHR OpenAPI supports filtering, pagination and sorting on all the listing endpoints.`}</p>
    <p>{`The default number of items retrieved by list endpoints is 100 (pagesize = 100). All listing endpoints have a header which lists provides further information to the client.`}</p>
    <p>{`The following fields are in the header`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hasPreviousPage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A boolean value set to true if there are items in a previous page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hasNextPage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A boolean value set to true if there are items in a following page`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`endCursor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An opaque identifier used to paginate. This is the tag to the last element in the list. Provide this to the list endpoints (using before or after parameter) to get elements before or after this element`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`startCursor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An opaque identifier used to paginate. This is the tag to the first element in the list. Provide this to the list endpoints (using before or after parameter) to get elements before or after this element`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`If the nextPage value is true then you have more that 100 records and must make additional calls to retrieve them`}</p>
    <h3>{`Filtering`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`filter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String that describes the filter that needs to be applied before the results are returned to the client`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The filter parameter is passed as a query string to the API endpoints. The value of this parameter is a search string that contains field:values together with boolean operators, which can be used to filter the result.`}</p>
    <p>{`An asterix (`}{`*`}{`) can also be used as a wildcard for partial matches on words. Logical operators (AND and OR) can be used between terms. Terms can be grouped via parenthesis to build complex search queries (see examples below).`}</p>
    <p>{`An asterix cannot be used by itself as a value. It must be part of a wildcard string`}</p>
    <p>{`The fields used in the field part of field:values must be one of the fields returned from the REST API endpoint.`}</p>
    <p><strong parentName="p">{`Some example filters:`}</strong></p>
    <p><inlineCode parentName="p">{`firstName:John`}</inlineCode>{` which would return employees whose firstName was John.`}</p>
    <p><inlineCode parentName="p">{`surname:Smith OR surname:Klein`}</inlineCode>{` which would return employees whose surname was either Smith or Klein.`}</p>
    <p><inlineCode parentName="p">{`surname:Smi*`}</inlineCode>{` which would return employees whose surname is Smith, Smits, Smithe, etc.`}</p>
    <p><inlineCode parentName="p">{`type:LegalEntity AND postcode:2000`}</inlineCode>{` which would return all branches that are Legal Entities and in postcode 2000.`}</p>
    <p><inlineCode parentName="p">{`postcode:2000 OR postcode:3000`}</inlineCode>{` which would return all branches in either postcode of 2000 or in postcode 3000.`}</p>
    <p><inlineCode parentName="p">{`type:LegalEntity AND (postcode:2000 OR postcode:3000)`}</inlineCode>{` which would return all branches that are Legal Entities and in postcode 2000 or 3000.`}</p>
    <p>{`An example would be `}<inlineCode parentName="p">{`filter=firstName:John`}</inlineCode></p>
    <p><strong parentName="p">{`Date filter:`}</strong></p>
    <p>{`Date range inclusive: `}<inlineCode parentName="p">{`dateUpdatedUTC:[2021-01-01 TO 2021-12-31]`}</inlineCode></p>
    <p>{`Date range exclusive: `}<inlineCode parentName="p">{`dateUpdateUTC:{2021-01-01 TO 2021-12-31}`}</inlineCode></p>
    <p>{`Unbounded range: `}<inlineCode parentName="p">{`dateUpdatedUTC:{* TO 2021-01-01}`}</inlineCode></p>
    <h3>{`Sorting`}</h3>
    <p>{`With enableHR OpenAPI, it is possible to return lists of elements that are sorted (ordered) according to specific criteria`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sort`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A string with fieldname:asc or fieldname:desc`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The parameter is a query parameter that hsould specify the field, followed by asc/desc, separated by : (colon)`}</p>
    <p>{`An example would be `}<inlineCode parentName="p">{`sort=firstName:desc`}</inlineCode></p>
    <h3>{`Pagination`}</h3>
    <p>{`Pagination is available on all listing endpoints. enableHR OpenAPI pagination is Cursor-based`}</p>
    <p>{`Every element in the list is associated with a unique ID (the cursor). Clients paginating through the list then provide the cursor of the starting element as well as a count of items to be retrieved or the cursor of the last element as well as a count of items to be retrieved.`}</p>
    <p>{`The following parameters can be used for paginating the results.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`first`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates the number of items that would be in the results. NB: Pagination would be invalidated if the filter/sort parms change on a subsequent call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`after`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Return results after the cursor. NB: Pagination would be invalidated if the filter/sort parms change on a subsequent call. This is an opaque string and has no meaning to the client`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`last`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates the number of items that would be in the results NB: Pagination would be invalidated if the filter/sort parms change on a subsequent call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`before`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`End Cursor for the requested Page. Return results before this cursor. Does not include the cursor element. NB: Pagination would be invalidated if the filter/sort parms change on a subsequent call.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Use of before and after will return unreliable results and only one of those should be used at any time`}</p>
    <h2>{`Examples`}</h2>
    <h3>{`Get first 100 employees after a cursor`}</h3>
    <p><a parentName="p" {...{
        "href": "https://rest.enablehr.com/accounts/%7BaccountId%7D/employees?after=aXRlbSMxMjM0&first=100"
      }}>{`https://rest.enablehr.com/accounts/{accountId}/employees?after=aXRlbSMxMjM0&first=100`}</a></p>
    <h3>{`Get the last 100 employees before a cursor`}</h3>
    <p><a parentName="p" {...{
        "href": "https://rest.enablehr.com/accounts/%7BaccountId%7D/employees?before=qaSDaXRlbSMxMjM0&last=100"
      }}>{`https://rest.enablehr.com/accounts/{accountId}/employees?before=qaSDaXRlbSMxMjM0&last=100`}</a></p>
    <h3>{`Sort the list of employees by firstName desc`}</h3>
    <p><a parentName="p" {...{
        "href": "https://rest.enablehr.com/accounts/%7BaccountId%7D/employees?sort=firstName:desc"
      }}>{`https://rest.enablehr.com/accounts/{accountId}/employees?sort=firstName:desc`}</a></p>
    <h3>{`Sort the list of employees by firstName desc and lastName asc`}</h3>
    <p><a parentName="p" {...{
        "href": "https://rest.enablehr.com/accounts/%7BaccountId%7D/employees?sort=firstName:desc,lastName:asc"
      }}>{`https://rest.enablehr.com/accounts/{accountId}/employees?sort=firstName:desc,lastName:asc`}</a></p>
    <h3>{`Filter the list of employees by firstName starting with J (can return John, James, Jerry etc)`}</h3>
    <p><a parentName="p" {...{
        "href": "https://rest.enablehr.com/accounts/%7BaccountId%7D/employees?firstName=J"
      }}>{`https://rest.enablehr.com/accounts/{accountId}/employees?firstName=J`}</a>{`*`}</p>
    <h3>{`Filter the list of employees by firstName starting with S and ending with t (can return Scott, Smith etc)`}</h3>
    <p><a parentName="p" {...{
        "href": "https://rest.enablehr.com/accounts/%7BaccountId%7D/employees?firstName=S*t"
      }}>{`https://rest.enablehr.com/accounts/{accountId}/employees?firstName=S*t`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      